<template>

  <div class="diary" v-if="list && list.length">

    <h2 class="divider-sec">
      {{ title }} 
      <nuxt-link class="h2_show_all" :to="link">
        {{ showmore }} 
        <!-- ({{ cnt }}) -->
      </nuxt-link>
    </h2>

    <div class="report_boxs reports_grid" v-if="list">      
      <GeneralDiary 
        v-for="(opt,ind) in list"
        :key="ind"  
        :data="opt"      
        >       
      </GeneralDiary>    
    </div>
  </div>

</template>

<script>
 


export default {   
  components: {   
    
  },
  props: [
    'title',
    'cnt',
    'showmore',
    'limit',
    'list',
    'link'
  ],
  data () {
    return {            
    }
  },
  computed:{
    
  },
  created() {    
    // this.query = this.$route.query.q;    
  },
  methods: {
     
  }
}
</script>

<style scoped>
 
 
 

.proslist {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}


.proslist.centered {
  justify-content: center;
}


</style>
