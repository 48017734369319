<template>

  <div class="pros" v-if="list && list.length">

    <h2 class="divider-sec">
      {{ title }} 
      <nuxt-link class="h2_show_all" :to="link">
        {{ showmore }} 
        <!-- ({{ cnt }}) -->
      </nuxt-link>
    </h2>

    <div class="questionlist" v-if="list">    
      <QuestionBox
        v-for="(opt,ind) in list.slice(0, 3)"
        :key="ind"  
        :data="opt"  

        :item_user="opt.item_user"
        :is_resolved="opt.is_resolved"
        :text="opt.text"
        :title="opt.title"
        :link="'/grow-questions/'+opt.link"
        :items_problem_photo="opt.items_problem_photo"
        :item_week="opt.item_week"
        :items_problem_symptom="opt.items_problem_symptom"
        :add_date="opt.add_date"
        :cnt_solutions="opt.cnt_solutions"
        :cnt_likes="opt.cnt_likes"
        />      
    </div>
  </div>

</template>

<script scoped>
  
export default {   
  components: {   
    
  },
  props: [
    'title',
    'showmore',
    'limit',
    'list',
    'cnt',
    'link'
  ],
  data () {
    return {            
    }
  },
  computed:{
    firstPhoto(){
      return this.items_photo && this.items_photo.length ? this.items_photo[0] : null;
    }
  },
  created() {    
    // this.query = this.$route.query.q;    
  },
  methods: {
     
  }
}
</script>

<style scoped>
 
 
 
/* questionlist */

.questionlist{ 
  width: 100%; 
  gap: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-count: 3;
}
.questionlist > div{ 
  max-width: 32%;
}

@container pb (max-width: 800px) {

  .questionlist > div{ 
    max-width: 48%;
  }
  .questionlist  {
    column-count: 2;
  }
}

@container pb (max-width: 500px) {


  .questionlist > div{ 
    max-width: 100%;
  }
  .questionlist  {
    column-count: 1;
  }
}



</style>
