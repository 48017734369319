<template>

  <div class="similar" v-if="list?.length">

    <h2 class="divider-sec">
      {{ title }}       
    </h2>

    <div class="list">
      <template v-for="pro in list">
        <NuxtLink class="item photo_photo" :to="pro.link">
          <div class="photo">
              <img :src="pro.avatar_little" :alt="pro.name">
          </div>
          <div :class="'user_range ' + 'u_range_' + pro.status"></div>
          <div class="name">
            {{ pro.name }}     
          </div>
          <div class="status">
            {{ pro.status && $constants.status[pro.status] ? $t($constants.status[pro.status]) : '' }}
            <!-- <span v-if="1" :class="'btn_chat ' + (pro.is_online ? 'online' : '')" :title="'Last visit ' + pro.visit_n_date" @click.prevent="$store.commit('chat/start', pro.id)"><i class="icon-message"></i></span> -->
          </div>
        </NuxtLink>
      </template>
    </div>
  </div>

</template>

<script>
 
export default {   
  components: {   
  },
  props: [
    'title',    
    'list',
  ],
  data () {
    return {            
    }
  },
  computed:{
    
  },
  created() {    
    
  },
  methods: {
     
  }
}
</script>

<style scoped>
 
 
 
.similar{

}

.similar .list{display: flex;flex-direction: row;flex-wrap: wrap;align-items: flex-start;height: 120px;overflow: hidden;}

.similar .list .item {width: 110px;display: flex;flex-direction: column;justify-content: center;margin-right: 10px;margin-bottom: 60px;position: relative;}
.similar .list .item .photo {margin-bottom: 0.5rem;width: 100%;height: 60px;margin: auto; position: relative; text-align: center; margin-bottom: 10px;}
.similar .list .item .photo img{border-radius: 150px;min-height: 60px;width: 60px; margin: 0 auto;}
.similar .list .item .name{text-align: center;font-size: 0.85rem;line-height: 1rem;}

.similar .list .item .user_range {font-size: 1.2rem;margin-top: 6px;margin-right: 15px; position: absolute;right: 8px;top: 34px;}

.similar .list .item .status {
    color: gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.75rem;
    margin-top: -1px;
    text-align: center;
}
.similar .list .item .status .btn_chat:hover{
    color: white;
    background-color: var(--un-primary-back-color);
}

.similar .list .item .status .btn_chat{
    display: inline-block;
    color: gray;
    font-size: 0.85rem;
    padding: 5px;
    border-radius: 3px;
    margin-left: 5px;
}
.similar .list .item .status .btn_chat.online{

    color: var(--un-primary-back-color);
    display: none;
}
</style>
