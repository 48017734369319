<template>
  <div class="info">      
      
    <SkGrowerOpenedInfo
      v-if="is_loading"
      />
      
    <GrowerInfoStat 
      v-else
      :data="grower_info_data"/>
  </div>
</template>




<script setup> 

const route = useRoute();
const { $api, $ga, $helper, $head } = useNuxtApp();


const props = defineProps({
  id: {
    type: Number
  }
})

var grower_id = ref(route.params.id);

const loadData = async (id) => {
  return await $api.getGrowerInfo(id);  
}

const { pending: is_loading,  data: grower_info_data } = await useLazyAsyncData('grower_info_data', async () => await loadData(props.id))

// useHead(useNuxtApp().$head.getGrowerInfo(grower_info_data.value));

</script>




<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
